// extracted by mini-css-extract-plugin
export var customText = "q_q5 d_dv d_cs d_cg";
export var videoIframeStyle = "q_qp d_d5 d_w d_H d_by d_b1 d_R";
export var customImageWrapper = "q_q6 d_cs d_cg d_Z";
export var customRow = "q_qK d_bD d_bf";
export var quoteWrapper = "q_gQ d_bC d_bP d_cs d_cg";
export var quoteBar = "q_qt d_H";
export var masonryImageWrapper = "q_qB";
export var title = "q_q7";
export var Title3Small = "q_q8 y_q8 y_r3 y_sb";
export var Title3Normal = "q_q9 y_q9 y_r3 y_sc";
export var Title3Large = "q_rb y_rb y_r3 y_sd";