// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "t_ry d_gv d_cs";
export var heroHeaderCenter = "t_gw d_gw d_cs d_dw";
export var heroHeaderRight = "t_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "t_rz d_gr d_cw";
export var heroParagraphCenter = "t_gs d_gs d_cw d_dw";
export var heroParagraphRight = "t_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "t_rB d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "t_rC d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "t_rD d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "t_rF d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "t_rG d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "t_rH y_rH";
export var heroExceptionNormal = "t_rJ y_rJ";
export var heroExceptionLarge = "t_rK y_rK";
export var Title1Small = "t_rL y_rL y_r3 y_r4";
export var Title1Normal = "t_rM y_rM y_r3 y_r5";
export var Title1Large = "t_rN y_rN y_r3 y_r6";
export var BodySmall = "t_rm y_rm y_r3 y_sm";
export var BodyNormal = "t_rn y_rn y_r3 y_sn";
export var BodyLarge = "t_rp y_rp y_r3 y_sp";