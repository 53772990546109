// extracted by mini-css-extract-plugin
export var alignLeft = "r_qQ d_bG";
export var alignCenter = "r_bP d_bD";
export var alignRight = "r_qR d_bH";
export var textAlignLeft = "r_rc";
export var textAlignCenter = "r_rd";
export var textAlignRight = "r_rf";
export var embedInnerWrapperDesign1 = "r_rg d_bM";
export var embed = "r_rh d_b1";
export var titleMargin = "r_rj d_cw d_dw";
export var subtitleMargin = "r_rk d_cs d_dw";
export var paragraphMargin = "r_rl d_cw d_dw";
export var SubtitleSmall = "r_qM y_qM y_r3 y_sf";
export var SubtitleNormal = "r_qN y_qN y_r3 y_sg";
export var SubtitleLarge = "r_qP y_qP y_r3 y_sh";
export var BodySmall = "r_rm y_rm y_r3 y_sm";
export var BodyNormal = "r_rn y_rn y_r3 y_sn";
export var BodyLarge = "r_rp y_rp y_r3 y_sp";